import axios from "@/plugins/axios";
import routes from "@/plugins/routes";

export function useUser() {
    const getLoggedInUser = () => {
        return axios
            .get(routes.user.getUser)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const getUserById = (id) => {
        return axios
            .get(routes.user.getUserById(id))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const getAllUsers = () => {
        return axios
            .get(routes.user.getUsers)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.resolve(null);
            });
    };

    const createUser = (payload) => {
        return axios
            .post(routes.user.createUser, payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const assignUserToStore = (payload) => {
        return axios
            .post(routes.user.assignStore, payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.resolve(null);
            });
    };

    const updateUser = (id, payload) => {
        return axios
            .put(routes.user.updateUser(id), payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const deleteUser = (id) => {
        return axios
            .delete(routes.user.deleteUser(id))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    return {
        getLoggedInUser,
        getUserById,
        createUser,
        assignUserToStore,
        updateUser,
        deleteUser,
        getAllUsers,
    };
}
