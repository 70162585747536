import axios from '@/plugins/axios'
import routes from '@/plugins/routes';

export function useAPI() {
	const doLogin = (payload) => {
        console.log('payload', JSON.stringify(payload));
		return axios.post(routes.auth.login, payload).then((response) => {
            console.log('response', JSON.stringify(response));
			return Promise.resolve(response.data);
		}).catch((err) => {
			return Promise.reject(err);
		});
	}

    const doLogout = () => {
        return axios.get(routes.auth.logout).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

	return {
		doLogin,
        doLogout
	}
}
