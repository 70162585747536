window.AndroidEventBus = {
  events: {},
  // Register an event listener
  on(event, listener) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
    return function() {
      window.AndroidEventBus.off(event, listener)
    }
  },

  // Emit an event to all listeners
  emit(event, ...args) {
    if (this.events[event]) {
      this.events[event].forEach(listener => listener(...args));
    }
  },

  // Remove a specific listener from an event
  off(event, listenerToRemove) {
    if (!this.events[event]) return;

    this.events[event] = this.events[event].filter(listener => listener !== listenerToRemove);
  },

  // Remove all listeners from an event
  offAll(event) {
    if (this.events[event]) {
      delete this.events[event];
    }
  }
}

window.AndroidScanner = {
  buttonEnabled: false,
  enableScannerButton: function() {
    window.AndroidScanner.buttonEnabled = true
  },
  disableScannerButton: function() {
    window.AndroidScanner.buttonEnabled = false
  },
  isAvailable: function() {
    try {
      console.log(Android)
      return true
    } catch (_) {
      return false
    }
  }
}

function onDocReady() {
  window.addEventListener('keydown', function(event) {
    if (window.AndroidScanner.buttonEnabled) {
      Android.onKeyDown(event.code)
    }
  })
}

if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive"){
  onDocReady();
} else {
  window.addEventListener("DOMContentLoaded", onDocReady);
}
