<template>
    <div
        class="w-full flex flex-col bg-background-1 h-[100dvh] overflow-hidden relative"
    >
        <HugoLines/>
        <Header/>

        <div class="w-full flex flex-col px-5 box-border">
            <div class="w-[30px] flex items-center justify-center mb-3">
                <img src="../assets/chevron-left-svgrepo-com.svg" class="w-[30px] -ml-4" @click="router.push(`/invoice-order/${route.params.invoiceID}`)"/>
            </div>
            <div class="flex w-full justify-between items-center">
                <div class="w-full flex flex-col">
                    <p class="text-md">CUTIE <span class="font-bold">{{box.code}}</span></p>
                    <div class="w-full flex mb-4">
                        <h1 class="text-2xl font-medium leading-9">Scaneaza produsele</h1>
                        <div class="flex flex-1"></div>
                        <ScanDropdownOptions
                            v-if="!invoiceLoading"
                            @reset-scan="resetScan"
                            @mark-box-scan-as-completed="markBoxScanAsCompleted"
                            :has-pending-items="itemsPendingUpload[route.params.boxID] && itemsPendingUpload[route.params.boxID].length > 0"
                            @upload-pending-items="uploadPendingItems"
                        />
                    </div>
                </div>
            </div>
            <div class="w-full border p-4 box-border text-xs flex justify-between">
                <span v-if="invoiceOrder">{{ invoiceOrder.filename }}</span>
                <span class="text-primary-1">{{ totalProductsInBox }} produse</span>
            </div>
            <div class="w-full flex flex-col mt-4">
                <span class="text-md">Progres</span>
                <div class="bg-gray-300 h-4 w-full rounded">
                    <div
                        class="bg-gray-600 h-4 rounded"
                        :style="{ width: scanProgress }"
                    ></div>
                </div>
                <div class="w-full text-center text-sm">
                    {{ `${scanProgressLabel}` }}
                </div>
            </div>
            <Button label="Scaneaza" class="mt-4" @click="startScan" :disabled="invoiceLoading" />
            <Button label="Adauga cod manual" color="grey" class="mt-4" @click="manualModalScanVisibility = true" :disabled="invoiceLoading"/>
        </div>
        <CameraFeed
            ref="CameraScanRef"
            v-show="scanInProgress && !isPhysicalScanner"
            @add-to-scanned-items="doScan"
            @add-to-scanned-items-and-continue="doScanAndContinue"
            @cancel-scan="cancelCameraScan"
            decoder="ean_reader"
        />
        <PhysicalScanner
            v-if="scanInProgress && isPhysicalScanner"
            @add-to-scanned-items="doScan"
            @add-to-scanned-items-and-continue="doScanAndContinue"
            @cancel-scan="cancelCameraScan"
            type="product"
        />
        <ScannedItemsList
            :scannedItems="scannedItems"
            @remove-item="removeItemFromScannedItems"
        />
    </div>
    <ManuallyAddCode
        v-if="manualModalScanVisibility"
        @close-modal="manualModalScanVisibility = false"
        @manual-scan="manuallyAddItemToScannedItems"
    />
</template>

<script setup>
import Button from '@/components/Button.vue';
import {computed, onMounted, ref} from 'vue';
import {useInvoiceOrderStore} from '@/store/useInvoiceOrderStore';
import {storeToRefs} from 'pinia';
import router from '@/router';
import CameraFeed from '@/components/CameraFeed.vue';
import Swal from 'sweetalert2';
import ScannedItemsList from '@/components/ScannedItemsList.vue';
import {useInvoiceOrder} from "@/composables/useInvoiceOrder";
import {useRoute} from "vue-router";

import Header from "@/components/Header.vue";
import HugoLines from "@/components/HugoLines.vue";
import ManuallyAddCode from "@/components/ManuallyAddCode.vue";
import ScanDropdownOptions from "@/components/ScanDropdownOptions.vue";
import {useBeep} from "@/composables/useBeep";
import AndroidScanner from "../AndroidScanner";
import PhysicalScanner from "@/components/PhysicalScanner.vue";

const {invoiceOrder, itemsPendingUpload} = storeToRefs(
    useInvoiceOrderStore()
);

const route = useRoute();

const {batchScan, getAllItemsForBox, markBoxAsCompleted, resetBox, removeScannedItem, getBox} = useInvoiceOrder();

const scanInProgress = ref(false);
const isPhysicalScanner = ref(false);
const CameraScanRef = ref(null);
const manualModalScanVisibility = ref(false);

const invoiceLoading = ref(false);

const box = ref({});
const boxItems = ref([]);
const totalProductsInBox = ref(0);

let unwatch = null;

function startScan() {
    scanInProgress.value = true;
    if(window.AndroidScanner.isAvailable()) {
        isPhysicalScanner.value = true;
    } else {
        const {init} = useBeep()
        init()

        CameraScanRef.value.startScan();
    }
}

function manuallyAddItemToScannedItems(item) {
    manualModalScanVisibility.value = false;

    addToScannedItems(item);
}

async function doScan(item) {
    scanInProgress.value = false;

    await addToScannedItems(item);
}

function doScanAndContinue(item) {
    addToScannedItems(item);
}

async function addToScannedItems(item) {
    const auxItems = [];
    auxItems.push(item);

    const payload = {
        invoiceOrderBoxId: route.params.boxID,
        items: auxItems
    }

    if(typeof itemsPendingUpload.value[route.params.boxID] != 'undefined' && itemsPendingUpload.value[route.params.boxID].length > 0) {
        payload.items = [...payload.items, ...itemsPendingUpload.value[route.params.boxID]];
    }

    try {
        const response = await batchScan(payload);

        boxItems.value = [...boxItems.value, ...response.notFoundItems, ...response.scannedItems];

        itemsPendingUpload.value[route.params.boxID] = [];

    } catch (e) {
        const id = Math.floor(100000000 + Math.random() * 900000000);
        const auxItem = {
            id: id,
            ean: item,
            price: 0,
            status: 'PENDING_UPLOAD',
            invoiceOrderBoxId: route.params.boxID,
            inside_invoice_order: true,
            name: 'N/A',
        }

        itemsPendingUpload.value[route.params.boxID].push(auxItem);
    }

    return;
}

function cancelCameraScan() {
    scanInProgress.value = false;
}

function resetScan() {
    Swal.fire({
        title: 'Esti pe cale sa resetezi progresul cutiei?',
        text: "Nu vei putea reveni asupra acestei actiuni",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Da, reseteaza!',
        cancelButtonText: 'Nu',
    }).then(async (result) => {
        if (result.isConfirmed) {
            await resetBox(route.params.invoiceID, route.params.boxID);
            scannedItems.value[route.params.boxID] = [];
            itemsPendingUpload.value[route.params.boxID] = [];
            Swal.fire('Reset!', 'Progresul cutiei a fost resetat.', 'success');
        }
    });
}

function markBoxScanAsCompleted() {
    Swal.fire({
        title: 'Esti sigur?',
        text: "Esti pe cale sa marchezi aceasta cutie ca si completata. Nu vei putea reveni asupra acestei actiuni!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Da, marcheaza-o!',
        cancelButtonText: 'Nu',
    }).then(async (result) => {
        if (result.isConfirmed) {
            if(itemsPendingUpload.value[route.params.boxID].length > 0) {
                try {
                    await uploadPendingItems()
                } catch (e) {
                    console.log(e);
                    return Swal.fire('Eroare!', 'Inainte de a inchide cutia am incercat uploadarea unor scan-uri facute fara conexiune la internet, ce au ramas pending. Din pacate nu s-a putut face upload-ul. Contacteaza echipa tehnica. Eroare:' + e, 'error');
                }
            }

            markBoxAsCompleted({id: route.params.boxID}).then(async() => {
                await Swal.fire('Cutie completa!', 'Cutia ta a fost marcata ca si completa', 'success')
                router.push({name: 'invoice-order', params: {invoiceID: route.params.invoiceID, boxID: route.params.boxID} });
            }).catch((err) => {
                console.log(err);
            });
        }
    });
}

async function uploadPendingItems() {
    const payload = {
        invoiceOrderBoxId: route.params.boxID,
        items: itemsPendingUpload.value[route.params.boxID]
    }

    try {
        const response = await batchScan(payload);

        boxItems.value = [...boxItems.value, ...response.notFoundItems, ...response.scannedItems];

        itemsPendingUpload.value[route.params.boxID] = [];

        return response;
    } catch (e) {
        const id = Math.floor(100000000 + Math.random() * 900000000);
        const auxItem = {
            id: id,
            ean: item,
            price: 0,
            status: 'PENDING_UPLOAD',
            invoiceOrderBoxId: route.params.boxID,
            inside_invoice_order: true,
            name: 'N/A',
        }

        itemsPendingUpload.value[route.params.boxID].push(auxItem);
    }
}

async function removeItemFromScannedItems(item) {
    try {
        await removeScannedItem(item.id);

        const index = boxItems.value.findIndex(i => i.id === item.id);
        boxItems.value.splice(index, 1);

        return Swal.fire(
            'Sters!',
            'Produsul a fost sters cu succes.',
            'success'
        )
    } catch (e) {
        if(e.response.data.message === 'extra_products_exists') {
            return Swal.fire(
                'Eroare!',
                'Nu poti sterge acest produs deoarece exista produse duplicate pentru el. Sterge mai intai produsele duplicate si apoi vei putea sterge acest produs principal',
                'error'
            )
        }
    }
}

const scanProgress = computed(() => {
    if(totalProductsInBox.value < scannedItems.value.length) {
        return 100
    }

    return (totalProductsInBox.value / scannedItems.value.length) * 100;
});

const scanProgressLabel = computed(() => {
    return `${ scannedItems.value.length } / ${totalProductsInBox.value}`;
});

const scannedItems = computed(() => {
    return boxItems.value.filter(item => {
        const status = ['PENDING_UPLOAD', 'SCANNED']
        return status.includes(item.status)
    }).reverse();
});

onMounted(async () => {
    if(!route.params.invoiceID) {
        return router.push({name: 'invoice-orders'});
    }

    if(!route.params.boxID) {
        return router.push({name: 'invoice-order', params: {invoiceID: route.params.invoiceID}});
    }

    invoiceLoading.value = true;

    try {
        box.value = await getBox(route.params.invoiceID, route.params.boxID)

        boxItems.value = await getAllItemsForBox(route.params.invoiceID, route.params.boxID);
        totalProductsInBox.value = boxItems.value.length;
    } catch (e) {
        console.log(e);
        return router.push({name: 'invoice-orders'});
    } finally {
        invoiceLoading.value = false;
    }
});
</script>
