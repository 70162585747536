<template>
    <div class="w-full bg-blue-200 p-3 box-border mt-2 border border-black/10 rounded">
        <p>
            User: <span class="font-bold">{{ log.author.name }}</span>
        </p>
        <p>
            Actiune: <span class="font-bold">{{ log.action }}</span>
        </p>
        <p>
            Data: <span class="font-bold">{{ new Date(log.created_at).toLocaleString('ro-RO') }}</span>
        </p>
        <p>
            Invoice Order: <span class="font-bold">{{ log.invoiceOrder.name }}</span>
        </p>
        <p>
            Invoice Status: <span class="font-bold">{{ log.invoiceOrder.status }}</span>
        </p>
    </div>
</template>

<script setup>
const props = defineProps({
    log: {
        type: Object,
        required: true
    }
});
</script>
