<template>
    <div class="w-full bg-red-200 p-3 box-border mt-2 border border-black/10 rounded">
        <p>
            User: <span class="font-bold">{{ log.author.name }}</span>
        </p>
        <p>
            Actiune: <span class="font-bold">Resetare status produs din Scanat in Pending</span>
        </p>
        <p>
            Data: <span class="font-bold">{{ new Date(log.created_at).toLocaleString('ro-RO') }}</span>
        </p>
        <p>
            EAN Produs: <span class="font-bold">{{ log.invoiceOrderItem.ean }}</span>
        </p>
        <p>
            Nume produs: <span class="font-bold">{{ log.invoiceOrderItem.name }}</span>
        </p>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    log: {
        type: Object,
        required: true
    }
});
</script>
