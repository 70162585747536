<template>
    <div
        class="w-full overflow-y-scroll min-h-[700px] bg-default-4 rounded-t-[16px] pt-0 px-5 box-border absolute bottom-0 transition-all duration-300"
        :class="[ isToggled ? 'translate-y-[0%] bottom-auto top-0' : 'translate-y-[95%]' ]"
    >
        <div
            class="flex w-full justify-center flex-col pt-4"
            @click="toggleList"
            v-touch:swipe.top="() => {isToggled = true}"
            v-touch:swipe.bottom="() => {isToggled = false}"
        >
            <div class="w-10 h-1 bg-black rounded self-center"></div>
            <h3 class="text-lg font-medium mt-6 items-star">{{scannedItems.length}} produse scanate</h3>
        </div>
        <div class="w-full flex flex-col mt-4" v-if="scannedItems.length > 0">
            <RecycleScroller
                :items="scannedItems"
                :item-size="50"
                key-field="id"
                class="scroller height-100 max-h-[550px]"
            >
                <template v-slot="{ item, index }">
                    <ScannedItem
                        :index="index"
                        :item="item"
                        :current-active-item="currentActiveItem"
                        @selectItem="currentActiveItem = $event"
                        @remove-item="removeItem"
                    />
                </template>
            </RecycleScroller>
        </div>
        <div v-else class="h-[150px] flex items-center justify-center">
            Nu exista produse scanate
        </div>
    </div>
</template>

<script setup>
import ScannedItem from "@/components/ScannedItem.vue";
import {ref} from "vue";
import Swal from "sweetalert2";
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

const props = defineProps({
    scannedItems: {
        type: Array,
        required: true
    }
})

const emit = defineEmits(['remove-item']);

const currentActiveItem = ref(null);

const isToggled = ref(false);

function removeItem(item) {
    currentActiveItem.value = item
    Swal.fire({
        title: 'Esti sigur ca vrei sa faci aceasta actiune?',
        text: "Nu vei putea reveni asupra acestei actiuni!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Da, sterge!',
        cancelButtonText: 'Nu, pastreaza'
    }).then((result) => {
        if (result.isConfirmed) {
            emit('remove-item', item);
        }
        currentActiveItem.value = null;
    })
}

function toggleList() {
    isToggled.value = !isToggled.value;
}
</script>
