import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import { createPinia, storeToRefs } from "pinia";
import { useUser } from "@/composables/useUser";
import { useUserStore } from "@/store/useUserStore";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Vue3TouchEvents from "vue3-touch-events";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
library.add(fas);

import 'sweetalert2/dist/sweetalert2.min.css';

app.use(router)
    .use(VueSweetalert2)
    .use(Vue3TouchEvents)
    .use(pinia)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");

const { getLoggedInUser } = useUser();
let { user } = storeToRefs(useUserStore());

const token = localStorage.getItem("accessToken");

if (token) {
    getLoggedInUser().then((response) => {
        if (response) {
            user.value = response;
        }
    }).catch((error) => {
       if(error.response.status === 401) {
           router.push("/login");
       }
    });
} else {
    router.push("/login");
}
