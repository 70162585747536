<template>
    <div class="w-full relative">
        <HugoLines />
        <Header />
        <div class="w-full flex flex-col bg-background-1 mi-h-[100dvh] relative">
            <div class="w-full flex flex-col px-4 box-border mb-6 mt-4">
                <div class="flex flex-1 mb-3">
                    <img src="../assets/chevron-left-svgrepo-com.svg" class="w-8 -ml-2" @click="router.push({name: 'invoice-orders'})" />
                    <div class="text-3xl font-medium">Boxes</div>
                </div>
                <div class="w-full flex">
                    <Button label="Scan" @click="doScan" :disabled="invoiceLoading" />
                    <div class="w-4"></div>
                    <Button label="Adauga cod manual" color="grey" @click="manualModalScanVisibility = true" :disabled="invoiceLoading"/>
                </div>
            </div>
            <div class="w-full flex-col px-4" v-if="invoiceLoading">
                <div class="w-full skeleton-loader h-20"></div>
            </div>
            <div class="w-full flex-col px-4" v-else>
                <div
                    class="w-full cursor-pointer flex flex-col mt-1 first:mt-0 py-2 px-3 rounded box-border"
                    :class="box.status === 'CLOSED' ? 'bg-green-300' : 'bg-white'"
                    v-for="(box, index) in sortedBoxes"
                    :key="box.id"
                    @click="goToBox(box)"
                >
                    <span class="text-lg">{{ parseInt(index + 1) + '. ' + box.code }}</span>
                    <div class="w-full flex justify-between mt-2">
                        <span>{{box.status}}</span>
                        <span>{{ formatDate(box.created_at) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <CameraFeed
            ref="CameraScanRef"
            v-show="scanInProgress && !isPhysicalScanner"
            @add-to-scanned-items="addToScannedBoxes"
            @cancel-scan="cancelCameraScan"
        />
        <PhysicalScanner
            type="box"
            v-if="scanInProgress && isPhysicalScanner"
            @add-to-scanned-items="addToScannedBoxes"
            @cancel-scan="cancelCameraScan"
        />
    </div>
    <ManuallyAddCode
        type="boxes"
        v-if="manualModalScanVisibility"
        @close-modal="manualModalScanVisibility = false"
        @manual-scan="manuallyAddBox"
    />
</template>

<script setup>
import HugoLines from "@/components/HugoLines.vue";
import Header from "@/components/Header.vue";
import Button from "@/components/Button.vue";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import Swal from 'sweetalert2';
import {useInvoiceOrder} from "@/composables/useInvoiceOrder";
import CameraFeed from "@/components/CameraFeed.vue";
import ManuallyAddCode from "@/components/ManuallyAddCode.vue";
import {useBeep} from "@/composables/useBeep";
import AndroidScanner from "../AndroidScanner";
import PhysicalScanner from "@/components/PhysicalScanner.vue";

const {getAllBoxesForInvoiceOrder, createBox} = useInvoiceOrder();

const router = useRouter();
const route = useRoute();

const invoiceLoading = ref(false);
const boxes = ref([]);

const CameraScanRef = ref(null);
const manualModalScanVisibility = ref(false);
const scanInProgress = ref(false);
const isPhysicalScanner = ref(false);

let unwatch = null;

function formatDate(date) {
    const auxDate = new Date(date);

    return auxDate.toLocaleDateString('en-GB', {
        day: '2-digit', // Use two digits for the day
        month: 'short', // Use the abbreviated month
        year: 'numeric' // Use the full year
    });
}

function doScan() {
    scanInProgress.value = true;

    if(window.AndroidScanner.isAvailable()) {
        isPhysicalScanner.value = true;
    } else {
        const { init } = useBeep()
        init()
        CameraScanRef.value.startScan();
    }
}

async function addToScannedBoxes(item) {
    scanInProgress.value = false;

    const payload = {
        insideInvoiceOrder: true,
        code: item
    }

    const index = boxes.value.findIndex(box => box.code === item);

    if(index !== -1) {
        return router.push({name: 'scan', params: {invoiceID: route.params.invoiceID, boxID: boxes.value[index].id}});
    }

    Swal.fire({
        title: 'Cutia nu a fost gasita',
        text: 'Codul SSCC nu este prezent in invoice order. Doresti sa creezi o cutie noua cu SSCC ' + item + ' si sa o adaugi la acest invoice order?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Da',
        cancelButtonText: 'Nu'
    }).then(async (result) => {
        if(result.isConfirmed) {
            try {
                const response = await createBox(route.params.invoiceID, payload);

                boxes.value.unshift(response);
            } catch (e) {
                console.log(e);
                Swal.fire({
                    title: 'Eroare',
                    text: 'A aparut o eroare la crearea cutiei. Te rugam sa incerci din nou.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        }
    });
}

function cancelCameraScan() {
    scanInProgress.value = false;
}

function manuallyAddBox(item) {
    manualModalScanVisibility.value = false;

    addToScannedBoxes(item);
}

function goToBox(box) {
    if(box.status !== 'PENDING') {
        return router.push({name: 'view-box', params: {invoiceID: route.params.invoiceID, boxID: box.id}});
    }
    router.push({name: 'scan', params: {invoiceID: route.params.invoiceID, boxID: box.id}});
}

const sortedBoxes = computed(() => {
    const closedBoxes =  boxes.value.filter(box => box.status === 'CLOSED').sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

    return [...closedBoxes, ...boxes.value.filter(box => box.status !== 'CLOSED')];
});

onMounted(async () => {
    if(!route.params.invoiceID) {
        return router.push({name: 'invoice-orders'});
    }

    invoiceLoading.value = true;

    try {
        boxes.value = await getAllBoxesForInvoiceOrder(route.params.invoiceID);
    } catch (e) {
        await Swal.fire({
            title: 'Eroare',
            text: 'A aparut o eroare la incarcarea cutiilor. Te rugam sa incerci din nou.',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        router.push({name: 'invoice-orders'});
    } finally {
        invoiceLoading.value = false;
    }
});

onBeforeUnmount(() => {
    if(unwatch) {
        unwatch();
        window.AndroidScanner.disableScannerButton()
    }
});
</script>

<style scoped>

</style>
