<template>
    <div class="w-full h-[100dvh] fixed bg-black z-20 top-0" ref="containerRef">
        <div
            class="w-[40px] h-[40px] bg-white flex items-center justify-center text-2xl rounded-full absolute top-6 right-6 left-auto z-[9999]"
            @click="cancelScan"
        >
            <div class="-mt-1">x</div>
        </div>

        <div
            class="w-[40px] h-[40px] bg-white flex items-center justify-center text-2xl rounded-full absolute top-[80px] right-6 left-auto z-[9999]"
            @click="restartScan"
        >
            <img src="@/assets/restart.svg" alt="Restart scan" class="w-6" />
        </div>
        <div class="w-full">
            <div class='spacer'></div>
            <div class='anim-box center spacer'>
                <!-- empty first child used for corners -->
                <div></div>
                <div class='scanner'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-lg'></div>
                <div class='anim-item anim-item-lg'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-lg'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-md'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-md'></div>
                <div class='anim-item anim-item-lg'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-lg'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-lg'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-lg'></div>
                <div class='anim-item anim-item-sm'></div>
                <div class='anim-item anim-item-md'></div>
            </div>
            <div class='spacer'></div>
        </div>
        <transition name="slide">
            <div
                class="flex items-center flex-col w-full absolute bottom-0 min-h-[250px] px-6 box-border bg-white rounded-t-xl pt-6 z-20 pb-4"
                v-if="scannedCode && scannedCode !== 'null'"
            >
                <!-- the fucking Chainway SDK returns null as string when there's nothing scanned and the time expired -->
                <span class="text-lg text-center">A fost descoperit codul</span>
                <h1 class="text-2xl font-bold mt-4 whitespace-normal break-all">{{ scannedCode }}</h1>
                <Button label="Salveaza" class="mt-6" color="gold" @click="completeScan"/>
                <Button label="Salveaza si continua scanarea" class="mt-4" color="black" @click="completeAndContinueScan"/>
                <Button label="Anuleaza" class="mt-4" color="black" @click="restartScan"/>
            </div>
            <div
                class="flex items-center flex-col w-full absolute bottom-0 min-h-[250px] px-6 box-border bg-white rounded-t-xl pt-6 z-20"
                v-else-if="scannedCode && scannedCode === 'null'"
            >
                <!-- the fucking Chainway SDK returns null as string when there's nothing scanned and the time expired -->
                <span class="text-lg text-center">Nu a fost descoperit un cod de bare</span>
                <Button label="Incearca iar" class="mt-6" color="gold" @click="restartScan"/>
                <Button label="Inchide" class="mt-4" color="black" @click="cancelScan"/>
            </div>
        </transition>
    </div>
</template>

<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import Button from "@/components/Button.vue";
import AndroidScanner from "../AndroidScanner";

const props = defineProps({
    type: {
        type: String,
        default: 'box'
    }
});

let unwatch = ref(null);

const emit = defineEmits(['addToScannedItems', 'cancelScan', 'addToScannedItemsAndContinue'])

const containerRef = ref(null);
const scannedCode = ref(null);

function completeScan() {
    emit('addToScannedItems', scannedCode.value);

    scannedCode.value = null;
}

function completeAndContinueScan() {
    emit('addToScannedItemsAndContinue', scannedCode.value);
    scannedCode.value = null;
    Android.startScan()
}

function restartScan() {
    scannedCode.value = null;
    Android.startScan()
}

function cancelScan() {
    scannedCode.value = null;
    emit('cancelScan');
}

onMounted(() => {
    containerRef.value.click();

    window.AndroidScanner.enableScannerButton()
    Android.startScan()

    unwatch.value = window.AndroidEventBus.on('barcode', function(barcode) {
        if(props.type === 'box') {
            scannedCode.value = barcode.substring(2);
        } else {
            scannedCode.value = barcode;
        }
    })
})

onUnmounted(() => {
    unwatch.value();
});
</script>

<style scoped>
.center {
    margin: 0 auto;
    text-align: center;
}

.spacer {
    margin-top: 6rem;
}

/* barcode container */
.anim-box {
    width: 220px;
    height: 100px;
    padding: 25px 30px;
    transition: transform .6s ease-out;
    transform-origin: center;
    transform: scale(1.5, 2) translateY(-50%) translateX(-33%);
    position: absolute;
    top: 50%;
    left: 50%;
}

/* adds the 4 corners */
.anim-box:before, .anim-box:after, .anim-box>:first-child:before, .anim-box>:first-child:after {
    position: absolute;
    width: 10%; height: 15%;
    border-color: white;
    border-style: solid;
    content: ' ';
}

/* top left corner */
.anim-box:before {
    top: 0; left: 0;
    border-width: 2px 0 0 2px;
}

/* top right corner */
.anim-box:after {
    top: 0; right: 0;
    border-width: 2px 2px 0 0;
}

/* bottom right corner */
.anim-box>:first-child:before {
    bottom: 0; right: 0;
    border-width: 0 2px 2px 0;
}

/* bottom left corner */
.anim-box>:first-child:after {
    bottom: 0; left: 0;
    border-width: 0 0 2px 2px;
}

/* barcode bars */
.anim-item {
    display: inline-block;
    background-color: white;
    height: 50px;
}

.anim-item-sm {
    width: 2px;
    margin-right: 3px;
}

.anim-item-md {
    width: 3px;
    margin-right: 2px;
}

.anim-item-lg {
    width: 5px;
    margin-right: 5px;
}

/* animated laser beam */
.scanner {
    width: 100%;
    height: 3px;
    background-color: red;
    opacity: 0.7;
    position:relative;
    box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
    top:50%;
    animation-name: scan;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running; /* Set animation to run continuously */
}

@keyframes scan {
    0% {
        box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
        top: 50%;
    }
    25% {
        box-shadow: 0px 6px 8px 10px rgba(170, 11, 23, 0.49);
        top: 5px;
    }
    75% {
        box-shadow: 0px -6px 8px 10px rgba(170, 11, 23, 0.49);
        top: 98%;
    }
}
</style>
